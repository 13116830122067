import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"


const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(0, 1)
    },
}))


const StyledButton = ({ children, ...props }) => {
    const classes = useStyles()

    return (
        <Button {...props} className={classes.button}>
            { children }
        </Button>
    )
}

const DesktopNavigation = (props) => {
    return (
        <AppBar position="static" color="transparent" elevation={0}>
            <Toolbar>
                <Typography variant="h6" color="primary">
                    Swizzo Studios
                </Typography>
                <Box ml="auto">
                    <StyledButton href="/">
                        Home
                    </StyledButton>
                    <StyledButton href="/about">
                        About
                    </StyledButton>
                    <StyledButton href="/services">
                        Services
                    </StyledButton>
                    <StyledButton href="/our-work">
                        Our Work
                    </StyledButton>
                    <StyledButton href="/contact" variant="outlined" color="primary">
                        Contact Us
                    </StyledButton>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default DesktopNavigation
