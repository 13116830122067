import React from "react"
import CssBaseline from "@material-ui/core/CssBaseline"
import Box from "@material-ui/core/Box"

import Navigation from "./navigation"


const Layout = (props) => {
    return (
        <React.Fragment>
            <CssBaseline />
            <Navigation />
            <Box>
                { props.children }
            </Box>
        </React.Fragment>
    )
}

export default Layout
