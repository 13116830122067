import React from "react"

import DesktopNavigation from "./desktop"
//import MobileNavigation from "./mobile"


const Navigation = (props) => {
    return (
        <DesktopNavigation />
    )
}

export default Navigation
